var config;
config = {
    templateBaseUrl: '/views/',
    useHtml5Mode: false,
    api_url: "https://api.lefresnoy.net/",
    rest_uri_v2: "https://api.lefresnoy.net/v2/",
    rest_uri: "https://api.lefresnoy.net/v1/",
    media_service: "https://media.lefresnoy.net/", 
    reset_password_uri: "https://api.lefresnoy.net/account/reset_password/",
    ame_rest_uri: "https://ame.lefresnoy.net/plugins/api_search/",

    kandidatur_api_url: "https://k-api.lefresnoy.net/",
    vimeo_rest_url:"https://api.vimeo.com/",
    vimeo_user_id: 27279451,

    analytics_live_api: {
        url:"https://lefresnoy.net/analytics/matomo",
        param:"index.php?module=API&method=Live.getCounters&idSite=5&lastMinutes=15&period=month&format=json&token_auth=bd9840fdd90778387a1c8e52ea61f876"
    }

};
